.radio {
  position: absolute;
  display: inline-block;
  width: 14rem;
  opacity: 0;

  & + label {
    position: relative;
    bottom: 5rem;
    display: inline-block;
    padding: 0 0 0 30rem;
    color: var(--black_1);
    cursor: pointer;
    font-size: 14rem;
    line-height: 14rem;
    white-space: nowrap;

    span {
      margin-left: 12rem;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 14rem;
      box-sizing: content-box;
      box-sizing: border-box;
      border: 1px solid var(--gray_1);
      aspect-ratio: 1 / 1;
      border-radius: 50%;
      content: '';
      transition: 0.1s;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }

  &:focus,
  &:checked {
    & + label::before {
      // box-shadow: inset 0 0 0 1px var(--white), inset 0 0 0 16rem var(--blue_6);
      border: 4rem solid var(--blue_7);
    }
  }
}
.variant {
  position: relative;
  &:not(:last-child) {
    margin-bottom: 10rem;
  }
}
