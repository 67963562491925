.msgWrap {
  width: 100%;
}

.textWrap {
  display: inline-block;
  width: 100%;
  min-height: 140px;
  box-sizing: border-box;
  padding: 20rem;
  border: 1px solid var(--gray_1);
  margin-top: 20px;
  background-color: var(--white);
  border-radius: 10px;
  word-wrap: break-word;
}
