.theme_secondary {
  background: var(--blue_secondary);
  color: var(--blue);

  &:hover {
    background: var(--blue_secondary_hover);
  }
}
.theme_secondary_but_darker {
  background: var(--blue_5);
  color: var(--blue);

  &:hover {
    background: var(--blue_6);
  }
}
.theme_like_input {
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 10rem;
  border: var(--gray_1) 1px solid;
  background: var(--white);
  border-radius: 8px;
  color: var(--black_1);
  &:hover {
    border: var(--black_1) 1px solid;
    background: var(--white);
  }
  &.active {
    border: var(--black_1) 1px solid;
    outline: none;
  }
}
.maButton {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  padding: 10rem 16rem;
  border: none;
  background: var(--blue_8);
  border-radius: 8px;
  color: var(--white);
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--blue_13);
  }

  &:active {
    background-color: var(--blue_14);
  }

  &:disabled {
    background-color: var(--gray_4);
    pointer-events: none;
  }
}
