// -Inter- //
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Inter/Inter-Regular.woff2') format('woff2'), url('../fonts/Inter/Inter-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Inter/Inter-Medium.woff2') format('woff2'), url('../fonts/Inter/Inter-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Inter/Inter-Bold.woff2') format('woff2'), url('../fonts/Inter/Inter-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  src: url('../fonts/Inter/Inter-SemiBold.woff2') format('woff2'), url('../fonts/Inter/Inter-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Inter/Inter-Black.woff2') format('woff2'), url('../fonts/Inter/Inter-Black.woff') format('woff');
}