.wrapper {
  position: absolute;
  z-index: 1000;
  bottom: 20rem;
  left: 50%;
  display: flex;
  overflow: hidden;
  height: 35rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 20rem;
  animation: fadeIn 0.3s both;
  background-color: rgba(var(--blue_8_rgb), 0.9);
  border-radius: 8px;
  box-shadow: inset 0 0 0 18px rgb(0, 0, 0, 0.16);
  transform: translateX(-50%);

  svg {
    margin-right: 20rem;
  }
}

.message {
  color: var(--white);
}

.alert {
  path {
    fill: var(--error_2);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
}
