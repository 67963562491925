.textareaLabel {
  display: block;
  margin-bottom: 6rem;
  color: var(--blue_8);
  font-size: 12rem;
  font-weight: 600;
  line-height: 17rem;
}

.textarea {
  position: relative;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  padding: 10rem;
  border: var(--gray_1) 1px solid;
  background: var(--white);
  border-radius: 8px;
  color: var(--black_1);
  font-size: 14rem;
  font-weight: 400;
  line-height: 17rem;
  resize: none;

  &:focus {
    border: var(--black_1) 1px solid;
    outline: none;
  }
}

.textarea::placeholder {
  color: var(--interactive_gray_basic);
}
.textarea::-webkit-input-placeholder {
  color: var(--interactive_gray_basic);
}
.textarea::-moz-placeholder {
  color: var(--interactive_gray_basic);
}
.textarea:-moz-placeholder {
  color: var(--interactive_gray_basic);
}
.textarea:-ms-input-placeholder {
  color: var(--interactive_gray_basic);
}

.textareaError {
  border-color: var(--error_basic);
}

.textarea::placeholder {
  color: #c4c4c4;
}

.wrapper {
  position: relative;
}

.disabled {
  pointer-events: none;
}
.disabled textarea {
  background: var(--interactive_gray_50);
}
