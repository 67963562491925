.email {
  margin: 20px 0;
  color: #37336e;
}

.btnsWrap {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}
.content {
  display: flex;
  min-width: 0;
  max-width: 1000rem;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.textBlock {
  max-width: 430rem;
  margin-right: 76rem;
}
.text {
  color: #37336e;
}
.iconContainer {
  display: flex;
  width: 440rem;
  height: 300rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media (max-width: 850px) {
  .unsubscribe {
    padding: 0 50rem;
  }
  .content {
    flex-direction: column;
  }
  .iconContainer {
    transform: scale(0.8);
  }
  .btnsWrap {
    justify-content: center;
  }
  .textBlock {
    margin: 0;
    margin-bottom: 30rem;
    text-align: center;
  }
}
@media (max-width: 450px) {
  .iconContainer {
    transform: scale(0.5);
  }
  .text {
    font-size: 20rem !important;
  }
}
