.button {
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: center;
  padding: 10rem 16rem;
  border: none;
  background: var(--blue);
  border-radius: 8px;
  color: var(--white);
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s;

  &:hover {
    background: var(--blue_hover);
  }
}
