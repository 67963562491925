:root {
  --white: #ffffff;
  --black: #000000;
  --gray: #aaaaaa;

  --black_1: #0b0b0b;
  --gray_text: #919191;
  --bg: #f2f2f2;
  --gray_1: #e8e8e8;
  --gray_2: #dae6f5;
  --gray_3: #f4f2fd;
  --gray_4: #bbc0c6;
  --shadow: #00000014;

  --blue: #1067f2;
  --blue_2: #00699a;
  --blue_3: #4f499e;
  --blue_4: #e0e9f7;
  --blue_5: #e0ecff;
  --blue_6: #bbd5ff;
  --blue_7: #267aff;
  --blue_8: #826aff;
  --blue_13: #6f54fd;
  --blue_14: #654afa;
  --blue_secondary: #ecf3fe;
  --blue_8_rgb: 55, 51, 110;
  --error_2: #ff3b45;

  --shadow_1: #61768f29;
  --shadow_3: rgba(130, 106, 255, 0.2);
  --blue_hover: #267aff;
  --blue_secondary_hover: #e0ecff;
}
