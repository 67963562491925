.block {
  overflow: hidden;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: var(--blue_4);
  border-radius: 12px;
}
.block:not(:first-of-type) {
  margin-top: 20px;
}

.name {
  color: var(--white);
}
.head {
  padding: 7px 30px;
  background-color: var(--blue_7);
}
.body {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: var(--white);
}
.body > *:not(:last-of-type) {
  margin-bottom: 24px;
}
.multiline {
  white-space: pre-wrap;
}
.requereReminder {
  margin-top: 20rem;
  color: var(--error_2);
}
