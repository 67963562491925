.linkButton {
  color: #fff;
  text-decoration: none;
}
.btnsContainer {
  display: flex;
  flex-direction: column;
  & > *:not(:last-of-type) {
    margin-bottom: 10rem;
  }
}

.main {
  display: flex;
  height: 100vh;

  align-items: center;
  justify-content: center;
}

.container {
  padding: 30rem;
  border: 1px solid var(--blue_4);
  margin-top: 20rem;
  background-color: #fff;
  background-color: var(--white);
  border-radius: 10px;
}

.title {
  margin-bottom: 30rem;
}
