.text {
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
}
.text_2 {
  font-size: 17rem;
  font-style: normal;
  font-weight: 400;
}
.text_2_hl {
  font-size: 17rem;
  font-style: normal;
  font-weight: 600;
}
.text_2_hl_1 {
  font-size: 17rem;
  font-style: normal;
  font-weight: 500;
}
.text_hl {
  font-size: 14rem;
  font-style: normal;
  font-weight: 500;
}

.text_1_hl_2 {
  font-size: 14rem;
  font-style: normal;
  font-weight: 600;
}
.text_36_600 {
  font-size: 36rem;
  font-style: normal;
  font-weight: 600;
}

.title {
  font-size: 24rem;
  font-style: normal;
  font-weight: 600;
}

.subtitle {
  font-size: 20rem;
  font-style: normal;
  font-weight: 600;
}
.text_20_400 {
  font-size: 20rem;
  font-style: normal;
  font-weight: 400;
}
.text_30_400 {
  font-size: 30rem;
  font-style: normal;
  font-weight: 400;
}
.text_14_400 {
  font-size: 14rem;
  font-style: normal;
  font-weight: 400;
}
