.body {
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 117px);
  background-color: var(--bg);
}

.pageContainer {
  width: 100%;
  height: 100%;
}
.blockFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  & > *:not(:last-of-type) {
    margin-right: 20px;
  }
}
.surveyPage {
  display: flex;
  max-width: 994px;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 30rem;
  margin: 40px auto 60px auto;
}
