.blocksDropdown {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 30rem;
  box-sizing: border-box;
  border: 1px solid #e8e8e8;
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.07);
  position: absolute;
  z-index: 10;
}
.row {
  display: flex;
  width: 100%;
  max-width: 994px;
  //   box-sizing: border-box;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  padding: 11rem 0;
  user-select: none;
}
.blockTitle {
  width: 100%;
  padding-right: 30rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dropContainer {
  position: absolute;
  top: 44rem;
  left: 0;
  width: 100%;
  padding: 0 30rem;
}
.ddContainer {
  position: relative;
  padding-bottom: 44rem;
}
.ddRow {
  &:not(:last-of-type) {
    border-bottom: 1px solid #e8e8e8;
  }
}
.currentRow {
  color: var(--blue_8);
}
