@import 'colors';

html {
  font-size: 1px;
  font-style: normal;
  font-weight: 400;
}

html,
body,
#root {
  width: 100%;
  // height: 100%;
}

body {
  color: var(--black_1);
  font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
  font-size: 16rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
}

button {
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  user-select: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.hidden {
  display: none;
}
@media (max-width: 1760px) {
  html {
    font-size: 0.9375px;
  }
}

@media (max-width: 1600px) {
  html {
    font-size: 0.90625px;
  }
}

@media (max-width: 1440px) {
  html {
    font-size: 0.875px;
  }
}
