.App {
  width: 100%;
  min-height: 100vh;
}

.App.noFocusHighlight :not(input) {
  &:focus {
    outline: none;
  }
}
