.page {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: var(--bg);
}

.pageWrap {
  display: flex;
  max-width: 1150px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 60rem 20rem 20rem;
  margin: 0 auto;

  & > * {
    margin-bottom: 40px;
  }
}
