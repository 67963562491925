.wrapper {
  display: flex;
  align-items: center;

  &:hover > * {
    cursor: pointer;
  }
}

.label {
  display: block;
  margin-left: 15rem;
  font-weight: 400;
  //  pointer-events: none;
}

.input {
  &[type='CHECKBOX'] {
    position: relative;
    width: 17rem;
    height: 17rem;
    box-sizing: border-box;
    border: 1px solid var(--gray);
    margin: 0;
    appearance: none;
    background-color: transparent;
    border-radius: 0.25rem;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;

    &:checked {
      border: 1px solid var(--blue);
      background-color: var(--blue);

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 11rem;
        height: 7.5rem;
        background: url('../../assets/icons/check.svg');
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        transform: translate(-50%, -50%);
      }
    }
  }
  &[type='RADIO'] {
    display: block;
    width: 14rem;
    opacity: 0;
    cursor: pointer;

    & + .radioLabel {
      position: absolute;
      top: 0;
      left: 0;
      width: 14rem;
      height: 14rem;
      box-sizing: border-box;
      border: 1px solid var(--gray);
      aspect-ratio: 1/1;
      border-radius: 50%;
      border-radius: 50%;
      opacity: 1;
      transition: 0.1s;
      pointer-events: none;
      cursor: pointer;
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 14rem;
        box-sizing: border-box;
        border: 1px solid var(--gray);
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        content: '';
        transform: translate(-50%, -50%);
        transition: 0.1s;
        pointer-events: none;
        cursor: pointer;
      }
    }

    &:checked {
      & + .radioLabel::before {
        border: 3px solid var(--blue);
      }
    }
  }
}

.inputWrapper {
  position: relative;
}
