.head {
  display: flex;
  height: 117px;
  align-items: center;
  justify-content: center;
  padding: 0 30rem;
  background-color: var(--blue_2);
}
.headContainer {
  display: flex;
  width: 994px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.name {
  padding-right: 20rem;
  color: var(--white);
}
.besinsLogo {
  width: 162px;
  height: 70px;
  & * {
    fill: var(--white);
  }
}

.finishMessage {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

.iconRobot {
  margin-top: 100px;
}
