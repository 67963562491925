.dotsCell {
  padding-right: 48rem;
  cursor: pointer;
  text-align: right;
}

.taskHoverBlock {
  position: absolute;
  z-index: 200;
  animation: fadeIn 0.3s ease-in-out;
}

.triggerContainer {
  display: flex;
  align-items: center;
}
.container {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  padding: 10rem;
  border: 1px solid var(--gray_2);
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0px 3px 6px var(--shadow_1);
}

.element {
  padding: 10rem;
  border-radius: 6px;
  color: var(--black_1);
  font-size: 14rem;
  font-weight: 400;
  text-align: left;
  transition: 0.2s;

  &:hover {
    background-color: var(--gray_3);
  }
  &:active {
    background-color: var(--gray_3);
  }
}

.triggerButton {
  position: relative;
  display: flex;
  width: 100%;
  min-width: 240rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 10rem 15rem;
  border: var(--blue_2) 1px solid;
  background: var(--white);
  border-radius: 8px;
  color: var(--blue_8);
  font-size: 14rem;
  text-align: start;
}

.triggerContainerActive button {
  :global(#arrow) {
    transform: rotate(180deg);
    transform-origin: 50% 50%;
  }
}

.dropDownOption {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10rem;

  svg {
    margin-right: 10rem;
  }
}

.label {
  display: block;
  margin: 0 0 10rem 0;
  margin-bottom: 6rem;
  color: var(--blue_8);
  font-size: 12rem;
  font-weight: 400;
  line-height: 17rem;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
