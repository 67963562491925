.table {
  display: table;
  min-width: 100%;
}

.head {
  display: table-header-group;
  box-shadow: 0px -1px 0px 0px var(--gray_1) inset;
}

.foot {
  display: table-footer-group;
  box-shadow: 0px 1px 0px 0px var(--gray_1) inset;
}

.tbody {
  display: table-row-group;
}

.row {
  display: table-row;
}

.cell {
  display: table-cell;
  overflow: hidden;
  box-sizing: border-box;
  padding: 20px 0;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.headEqual {
  width: calc(50% / 4);
}

.headCell {
  padding: 10rem 10rem;
  font-weight: 500;
  text-align: center;
}

.mainCell {
  max-width: 150px;
}

.checkCell {
  min-width: 10rem !important;
  padding: 0 20px;
}
.checkContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footRow {
  display: flex;
  align-items: center;
  padding-top: 30rem;
  margin-left: -10rem;
}

.label {
  padding-left: 6px;
  color: var(--gray_text);
  cursor: pointer;
  user-select: none;
}
