.wrap {
  display: flex;
  width: 100%;
  min-height: 84px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  padding: 20rem;
  border: 1px solid var(--gray_1);
  margin-top: 20px;
  background-color: var(--white);
  border-radius: 10px;
  transition: background-color 0.2s;

  &.wrapGray {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

.speciality {
  margin-top: 10px;
  color: var(--gray_text);
}

.rightBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 20px;
}

.status {
  padding: 10rem 16rem;
  color: var(--gray_text);
  line-height: 14px;
}

.leftBlockGray {
  filter: opacity(0.5);
}
