.scrollArea :global {
  .simplebar-content {
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      background: transparent;
    }
  }

  .simplebar-content-wrapper {
    outline: none;
    &::-webkit-scrollbar {
      display: none;
      width: 0;
      background: transparent;
    }
  }

  .simplebar-scrollbar {
    &::before {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #8a9fb150;
    }
  }

  .simplebar-scrollbar.simplebar-visible::before {
    opacity: 1;
  }

  .simplebar-track {
    z-index: 0;
    border-radius: 0;
  }

  .simplebar-vertical {
    right: 5rem;
    width: 16rem;
    height: 100%;
  }

  .simplebar-horizontal {
    bottom: 5rem;
    width: 100%;
    height: 16rem;
  }

  .simplebar-vertical .simplebar-scrollbar {
    &::before {
      left: auto;
      width: 5rem;
      transform-origin: right center;
      transition: width 0.3s, opacity 0.3s;
    }
  }
  .simplebar-horizontal .simplebar-scrollbar {
    top: 0;
    height: 16rem;
    &::before {
      top: auto;
      height: 5rem;
      transform-origin: center bottom;
      transition: height 0.3s, opacity 0.3s;
    }
  }

  .simplebar-vertical.simplebar-hover {
    .simplebar-scrollbar::before {
      width: 62.5%;
    }
  }

  .simplebar-horizontal.simplebar-hover {
    .simplebar-scrollbar::before {
      height: 62.5%;
    }
  }
}

.scrollArea.onlyVertical :global {
  .simplebar-horizontal {
    display: none;
  }
}
