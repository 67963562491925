.question {
  overflow: hidden;
  width: 100%;
  border-width: 1rem;
  border-style: solid;
  border-color: var(--blue_4);
  border-radius: 12px;
}
.question:not(:first-of-type) {
  margin-top: 20px;
}

.name {
  color: var(--white);
}
.head {
  padding: 7px 30px;
  background-color: var(--blue_3);
}
.body {
  padding: 30px;
  background-color: var(--white);
}
.body > *:not(:last-of-type) {
  margin-bottom: 24px;
}
.answerContainer {
  margin-top: 24px;
}
.requireStar {
  color: var(--error_2);
}
.title {
  color: var(--black_1);
}
.error {
  border: 1rem solid var(--error_2);
}
