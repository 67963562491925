.pageWrap {
  display: flex;
  height: 100vh;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0 100rem;
}

.tableWrap {
  display: flex;
  max-width: 1000rem;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.email {
  margin: 20px 0;
}

.btnsWrap {
  display: flex;
  margin-top: 60px;
  gap: 20px;
}
